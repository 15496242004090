import React, { useState } from 'react';
import './FAQ.css'; // Assure-toi d'importer le CSS

const FAQ = () => {
  const [openIndex, setOpenIndex] = useState(null); // État pour contrôler quelle réponse est ouverte

  const faqs = [
    {
      question: "Quelles sont les tailles de box disponibles ?",
      answer: "Nous proposons une variété de tailles, de 6m³ à 60m³, pour s'adapter à tous vos besoins.",
    },
    {
      question: "Comment réserver un box ?",
      answer: "Vous pouvez réserver votre box directement sur notre site web ou en nous contactant par téléphone.",
    },
    {
      question: "Quelle sécurité pour nos box ?",
      answer: "Oui, nos installations sont sécurisées 24/7 avec vidéosurveillance et détecteur de lumière, pour assurer la sécurité optimale de vos biens..",
    },
    
    {
      question: "Comment résilier mon abonnement ?",
      answer: " Il vous suffit de donner un préavis de 15 jours pour mettre fin à votre location. Aucun dépôt de garantie, aucune taxe supplémentaire, et aucun engagement à long terme.",
    },

    {
      question: "Quand puis-je accéder à mon espace de rangement ? ",
      answer: " Vous trouverez ci-dessous les heures où vous pouvez venir récupérer ou déposer des affaires dans votre local de stockage : Lundi : 24h/24 - Mardi : 24h/24 - Mercredi : 24h/24 - Jeudi : 24h/24 - Vendredi : 24h/24 - Samedi : 24h/24 - Dimanche : 24h/24.",
    },
    
    // Ajoutez d'autres questions ici
  ];

  const toggleFAQ = (index) => {
    setOpenIndex(openIndex === index ? null : index); // Ouvre/ferme la réponse
  };

  return (
    <section className="faq">
      <div className="container">
        <h2 className="faq-title">Foire aux questions</h2> {/* Ajout de la classe ici */}
        <div className="faq-list">
          {faqs.map((faq, index) => (
            <div key={index} className="faq-item">
              <div className="faq-question" onClick={() => toggleFAQ(index)} style={{ cursor: 'pointer' }}>
                {faq.question}
                <span className="arrow">{openIndex === index ? '▼' : '►'}</span> {/* Flèche qui change */}
              </div>
              {openIndex === index && (
                <div className="faq-answer">{faq.answer}</div> // Affiche la réponse si ouverte
              )}
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default FAQ;

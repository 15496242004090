// src/components/OffersAndRates.js
import React from 'react';
import './OffersAndRates.css'; // Assure-toi d'importer le CSS

const OffersAndRates = () => {
  const offers = [
    {
      size: "6m³",
      price: "29.30€ TTC / mois",
      description: "Idéal pour stocker quelques meubles ou des cartons. Parfait pour les étudiants ou les petites affaires.",
    },
    {
      size: "12m³",
      price: "64.40€ TTC / mois",
      description: "Un espace intermédiaire pour les familles ou ceux qui ont besoin de stocker des articles saisonniers.",
    },
    {
      size: "24m³",
      price: "124€ TTC / mois",
      description: "Un grand espace pour les entreprises ou les personnes ayant besoin de stocker beaucoup d'objets.",
    },
    {
      size: "60m³",
      price: "301.80€ TTC / mois",
      description: "Un grand espace pour les entreprises ou les personnes ayant besoin de stocker beaucoup d'objets.",
    },
    
    
  ];
  

  return (
    <div id="offers-section" className="offers-and-rates">
      <h2>Offres et Tarifs</h2>
      
      <div className="offers-container">
        {offers.map((offer, index) => (
          <div className="offer-card" key={index}>
            <h3>{offer.size}</h3>
            <p className="price">{offer.price}</p>
            <p>{offer.description}</p>
          </div>
        ))}
      </div>
      
      <p>Trouvez la taille de box idéale et découvrez nos tarifs <a href="https://i.postimg.cc/BbqSqWVn/prix-sos-box.jpg"target="_blank" rel="noopener noreferrer">ici</a></p>
    </div>
  );
};

export default OffersAndRates;

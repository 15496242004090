import React from 'react';
import './QuestionsSelection.css'; // Assurez-vous que le chemin d'accès est correct

function QuestionsSelection() {
  const questions = [
    {
      question: 'Quelle est la taille minimale d\'un garde-meuble ?',
      answer: 'La superficie minimale requise pour un garde-meuble est de 6 mètre cube.',
    },
    {
      question: 'Comment accéder à mon garde-meuble ?',
      answer: 'Vous pouvez accéder à votre garde-meuble 24/7 avec un code d\'accès.',
    },
    {
      question: 'Puis-je annuler ma réservation ?',
      answer: 'Oui, vous pouvez annuler votre réservation jusqu\'à 24 heures avant l\'arrivée.',
    },
    {
      question: 'Est-ce que je peux partager mon espace ?',
      answer: 'Non, chaque espace de garde-meuble est réservé à une seule personne.',
    },
  ];

  return (
    <section className="questions-section">
      <div className="container">
        <h2 className="questions-title">Questions récurrentes</h2>
        <div className="questions-list">
          {questions.map((item, index) => (
            <div key={index} className="question-item">
              <h3>{item.question}</h3>
              <p>{item.answer}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

export default QuestionsSelection;

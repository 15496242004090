import React from 'react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import './MapSection.css';

// Assure-toi que l'icône du marqueur est correctement configurée
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

function MapSection() {
  const mapCenter = { lat: 45.6486, lng: 0.1558 };
  const locations = [
    { lat: 45.651939, lng: 0.184707, title: '8 Rue Jean Marchais 16000 ANGOULEME' },
    
  ];

  return (
    <section className="map-section">
      <div className="container">
        <h2 className="map-title">Trouvez votre garde-meuble près de chez vous !</h2>
        <p className="map-description">Découvrez nos centres dans la région</p>
        <MapContainer center={mapCenter} zoom={12} style={{ height: "400px", width: "100%" }}>
          <TileLayer
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          />
          {locations.map((location, index) => (
            <Marker key={index} position={{ lat: location.lat, lng: location.lng }}>
              <Popup>{location.title}</Popup>
            </Marker>
          ))}
        </MapContainer>
      </div>
    </section>
  );
}

export default MapSection;

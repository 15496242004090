// src/components/PrivacyPolicy.js
import React from 'react';
import './TermsOfUse.css'; // Assure-toi que ce fichier CSS existe


const PrivacyPolicy = () => {
  return (
    <div className="terms-of-use">
      <h1>Politique de Confidentialité</h1>
      <p> Date de publication : 09 décembre 2024      </p>

      <h2>1. Préambule </h2>
      <p> La présente politique de confidentialité décrit les méthodes de collecte, d'utilisation et de protection des Données personnelles par PINACLE SARL dans le cadre de l'utilisation de son Site et de ses services. PINACLE SARL s'engage à respecter la confidentialité de vos Données personnelles conformément au Règlement Général sur la Protection des Données (RGPD) et à la législation applicable. En utilisant notre Site ou nos services, vous acceptez les termes de cette politique. 
La Politique de Confidentialité pourra être modifiée à tout moment, en particulier, pour tenir compte notamment de l’évolution des traitements des Données personnelles mis en œuvre et de la législation applicable.       </p>

      <h2>2. Définitions </h2>
      <p> Les termes commençant par une majuscule sont soit définis aux présentes, soit ont le sens qui leur est donné par le Règlement (UE) 2016/679 du 27 avril 2016 relatif à la protection des personnes physiques à l'égard du traitement des données à caractère personnel et à la libre circulation de ces données (« RGPD »), 
        tels que notamment les termes « Données à caractère personnel » ou « Données personnelles » « Responsable de traitement », « Sous-traitant », « Destinataire ». </p>

      <h2>3. Responsable du traitement   </h2>
      <p> PINACLE SARL est le Responsable des traitements des Données personnelles, en tant que professionnel proposant aux particuliers un service de location d’espaces de stockage.  
          PINACLE SARL est soumis en cette qualité aux obligations imposées par la réglementation en vigueur en France et au sein de l’Union européenne en matière de Données à caractère personnel en particulier le RGPD. 
          Les personnes concernées par le traitement des Données personnelles sont les clients et prospects de PINACLE SARL incluant notamment les utilisateurs.      </p>
      <h2>4. Données collectées  </h2>
      <p> PINACLE SARL limite la collecte des Données à caractère personnel aux données adéquates, pertinentes et strictement nécessaires aux traitements et à la gestion de la relation commerciale. 
          La non-fourniture de ces Données personnelles peut, selon le cas, avoir pour conséquence l’impossibilité de conclure le contrat et/ou de fournir les services et/ou de vous proposer des offres commerciales. 
          Les Données personnelles collectées peuvent inclure : 
          Données d’identification : nom, prénom, adresse postale, adresse e-mail, numéro de téléphone. 
          Données de facturation : coordonnées bancaires, adresse de facturation. </p>

      <h2>5. Collecte des Données personnelles/données  </h2>
      <p> Formulaires de contact et de demande de devis : données fournies volontairement par l’utilisateur pour obtenir des informations ou un devis. 
          Cookies et outils d’analyse : données techniques collectées automatiquement lors de la navigation sur le Site (voir section Cookies). 
          Communication directe : données fournies lors d’échanges avec le service client.  </p>

      <h2>6. Traitements réalisés sur les Données personnelles   </h2>
      <p> Les Données personnelles collectées peuvent faire l’objet des actions suivantes : 
Collecte et enregistrement : 
Intégration des Données personnelles dans les systèmes d’information de PINACLE SARL. 
Stockage initial dans des bases de données sécurisées. 
Organisation et structuration : 
Tri et classification des Données personnelles pour des usages spécifiques (gestion client, facturation, suivi des interactions). 
Structuration dans des logiciels ou plateformes de gestion. 
Hébergement et conservation : 
Stockage des Données personnelles sur des serveurs sécurisés situés dans l’Union Européenne ou auprès de prestataires conformes au RGPD. 
Consultation : 
Accès autorisé par les personnes habilitées ; 
Consultation des Données personnelles pour répondre aux demandes des utilisateurs ou gérer les services. 
Communication et transmission : 
Partage des Données personnelles avec les services concernés  
Transmission aux prestataires ou sous-traitants partenaires dans le cadre de l’exécution des services, sous réserve de garanties contractuelles appropriées. 
Modification et mise à jour : 
Correction ou mise à jour des Données personnelles pour maintenir leur exactitude. 
Modification des informations à la demande de l’utilisateur ou en cas de besoin opérationnel. 
Tri et archivage : 
Tri des Données personnelles pour séparer les informations actives des Données personnelles devant être archivées. 
Archivage des données en conformité avec les exigences légales ou à des fins de sauvegarde. 
Effacement ou destruction : 
Suppression définitive des Données personnelles après expiration des délais de conservation ou à la demande de l’Utilisateur, dans les limites prévues par la loi. 
Destruction sécurisée des Données personnelles physiques ou numériques pour prévenir tout accès non autorisé. 
Mesures supplémentaires 
Pour garantir un traitement conforme et sécurisé : 
PINACLE SARL limite l’accès aux Données personnelles aux seuls personnes habilités (prestataires/fournisseurs), dans le cadre de leurs fonctions. 
Toutes les actions réalisées sur les Données personnelles sont encadrées par des mesures de sécurité techniques et organisationnelles rigoureuses, notamment : 
Authentification par identifiant et mot de passe. 
Chiffrement des données sensibles (ex. : données bancaires). 
Contrôle des accès et suivi des actions sur les données pour assurer leur traçabilité. 
Ces actions et traitements respectent les principes fondamentaux du RGPD, notamment la transparence, la sécurité, la minimisation des Données personnelles et la limitation de leur conservation à ce qui est strictement nécessaire. </p>

      <h2>7. Destinataires des Données personnelles   </h2>
      <p> Vos Données personnelles peuvent être partagées avec : 
          Les services internes de PINACLE SARL. 
          Nos prestataires techniques (hébergement, etc.). 
          Les autorités légales ou administratives si cela est requis. 
          PINACLE SARL s’assure que tous ses partenaires respectent les règles de protection des Données personnelles. </p>

      <h2>8. Conservation des Données personnelles  </h2>
      <p> PINACLE SARL conserve vos Données personnelles pour une durée adaptée à la finalité de chaque traitement. La durée de conservation dépend de votre statut (client ou prospect) et des exigences légales applicables. 
          Durées générales de conservation : 
          Pour les prospects : vos Données personnelles sont conservées jusqu’à 1 an après le dernier contact, sauf opposition de votre part. 
          Pour les clients : vos Données personnelles sont conservées pendant toute la durée du contrat, puis 1 an après la cessation du contrat. 
          Durées spécifiques et exceptions : 
          Obligations légales et réglementaires : certaines Données personnelles peuvent être conservées pour des durées plus longues si des lois ou règlements l’exigent (par exemple, 5 ans pour les données fiscales). 
          Gestion des réclamations et contentieux : en cas de litige ou de réclamation, vos Données personnelles pourront être conservées jusqu’à la résolution définitive du différend. 
          Ces durées de conservation sont appliquées pour garantir la conformité aux lois en vigueur et pour protéger vos droits. Une fois les durées écoulées, vos Données personnelles sont supprimées ou anonymisées de manière sécurisée. </p>

      <h2>9. Vos droits  </h2>
      <p> Conformément au Règlement Général sur la Protection des Données (RGPD), vous bénéficiez des droits suivants concernant vos Données personnelles : 
          Droit d’accès : Vous pouvez demander à obtenir une copie des Données personnelles vous concernant et des informations sur leur traitement. 
          Droit de rectification : Vous avez le droit de corriger ou compléter des Données personnelles inexactes ou incomplètes. 
          Droit à l’effacement : Vous pouvez demander la suppression de vos Données personnelles, sous réserve des obligations légales de conservation. 
          Droit d’opposition : Vous pouvez vous opposer au traitement de vos Données personnelles pour des motifs légitimes ou refuser leur utilisation à des fins de prospection commerciale. 
          Droit à la portabilité : Vous pouvez récupérer vos Données personnelles dans un format structuré, couramment utilisé et lisible par machine, ou demander leur transfert direct à un autre responsable de traitement, lorsque cela est techniquement possible. 
          Droit à la limitation : Vous pouvez demander une restriction temporaire du traitement de vos Données personnelles dans certains cas prévus par la loi. 
          Directives post-mortem 
          Conformément aux dispositions légales en vigueur, vous avez le droit de définir des directives relatives à la conservation, à l’effacement et à la communication de vos Données personnelles après votre décès. 
          Retrait du consentement 
          Lorsque le traitement de vos Données personnelles repose sur votre consentement, vous pouvez retirer ce dernier à tout moment, sans que cela n’affecte la licéité du traitement effectué avant le retrait. 

  </p>

      <h2>10. Exercice de vos droits – Formulaire de contact - CNIL  </h2>
      <p> Pour exercer ces droits, vous pouvez nous contacter par e-mail via le formulaire de contact : https://www.angouleme-sosbox.fr/contact. 
          Votre demande sera traitée dans les meilleurs délais, sous réserve de justifier de votre identité en joignant une copie de votre titre officiel d’identité à votre e-mail ou courrier. 
          Vous pouvez également déposer une réclamation auprès de la CNIL (Commission Nationale de l’Informatique et des Libertés) – www.cnil.fr – 3 place de Fontenoy 75007 Paris. </p>

      <h2>11. Localisation et sécurité des Données personnelles  </h2>
      <p> L’ensemble des Données personnelles des utilisateurs collectées par PINACLE SARL est hébergé au sein de l’Union Européenne, chez OVH SAS, immatriculée sous le SIREN 424761419 conformément à la réglementation applicable en matière de protection des Données personnelles. 
          PINACLE SARL met en œuvre toutes les précautions nécessaires pour garantir la confidentialité et la sécurité des Données personnelles afin d’en empêcher l’altération, la corruption ou l’accès par des personnes non autorisées. Une politique de sécurité rigoureuse a été adoptée, comprenant notamment : 
          Un accès limité aux seules personnes ou services ayant besoin de consulter les Données personnelles pour des raisons professionnelles. 
          Le stockage des Données personnelles sur des serveurs sécurisés. 
          Cependant, bien que des mesures de sécurité avancées soient mises en place, PINACLE SARL rappelle que la transmission de Données personnelles par Internet comporte des risques inhérents. Par conséquent, PINACLE SARL ne peut garantir la sécurité des Données personnelles pendant leur transit sur Internet, sous réserve de respecter ses obligations légales et contractuelles. </p>

      <h2>12. Modifications de la politique   </h2>
      <p> PINACLE SARL se réserve le droit de modifier cette politique de confidentialité à tout moment. Les modifications seront publiées sur cette page avec la date de mise à jour. Nous vous encourageons à consulter régulièrement cette politique. </p>

      <h2>12. Contact  </h2>
      <p> Pour toute question relative à la présente politique de confidentialité, vous pouvez nous contacter via le formulaire de contact accessible au lien suivant : https://www.angouleme-sosbox.fr/contact.  </p>




    </div>
  );
};

export default PrivacyPolicy;

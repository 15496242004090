// Dans Testimonials.js
import React from 'react';
import './Testimonials.css'; // Importation du fichier CSS

const Testimonials = () => {
  const testimonials = [
    { name: 'Bernadette Boy.', comment: 'Super propre , matériel à disposition pour charger! Facilité, rapidité ! Très bon accueil', rating: 5 },
    { name: 'Elisabeth Brothier.', comment: 'Facilite d accès tous les jours et à toute heure, bonne sécurisation du site.', rating: 4 },
    { name: 'Veronique Ardies.', comment: 'Topissime :)) !', rating: 5 },
  ];


  const renderStars = (rating) => {
    return '★'.repeat(rating) + '☆'.repeat(5 - rating);
  };

  return (
    <section className="testimonials">
      <div className="container">
        <h2 className="testimonials-title">Ce que disent nos clients</h2> {/* Ajout de la classe ici */}
        <div className="testimonial-grid">
          {testimonials.map((testimonial, index) => (
            <div key={index} className="testimonial-item">
              <p>{testimonial.comment}</p>
              <p className="testimonial-author">{testimonial.name}</p>
              <div className="rating">{renderStars(testimonial.rating)}</div>
            </div>
          ))}
        </div>
        <p>Accédez à l'ensemble de nos avis clients <a href="https://www.location-gardemeuble.fr/box-garde-meuble-angouleme-sos-box-572#gm-avis"target="_blank" rel="noopener noreferrer">ici</a></p>

      </div>
    </section>
  );
};

export default Testimonials;

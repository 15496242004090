import React, { useState } from 'react';
import './ReservationForm.css'; // Assure-toi d'importer le CSS
import { useNavigate } from 'react-router-dom';

const ReservationForm = () => {
  const navigate = useNavigate(); // Hook pour la navigation
  const [date, setDate] = useState('');
  const [time, setTime] = useState('');
  const [isSubmitted, setIsSubmitted] = useState(false); // État pour le message de succès

  const handleBack = () => {
    navigate(-1); // Retourne à la page précédente
  };

  if (isSubmitted) {
    return (
      <div className="reservation-form-container">
        <div className="reservation-form">
          <h2>Merci !</h2>
          <p>Merci pour votre réservation ! Nous vous recontacterons dans les plus brefs délais afin de répondre à votre demande. Nous vous remercions pour votre patience et votre confiance.
            N'hésitez pas à nous contacter si vous avez des questions supplémentaires. Nous restons à votre disposition pour toute assistance.</p>
          <button onClick={() => navigate('/')}>Retourner au site originel</button>
        </div>
      </div>
    );
  }

  return (
    <div className="reservation-form-container">
      <div className="reservation-form">
        {/* Croix en haut à droite */}
        <button className="close-button" onClick={handleBack}>✖</button>

        <h2>Réserver une visite</h2>
        <form action="https://formspree.io/f/xbljlrya" method="POST">
          <input type="text" name="name" placeholder="Votre nom" required />
          <input type="email" name="email" placeholder="Votre email" required />
          <input type="Téléphone" name="Téléphone" placeholder="Votre Téléphone" required />
          <textarea name="message" placeholder="Votre message" required></textarea>

          {/* Champ pour sélectionner la date */}
          <label htmlFor="date">Sélectionnez une date :</label>
          <input 
            type="date" 
            id="date" 
            name="date" 
            value={date} 
            onChange={(e) => setDate(e.target.value)} 
            required 
            min={new Date().toISOString().split('T')[0]} // Mettre la date d'aujourd'hui comme minimum
          />

          {/* Champ pour sélectionner le créneau horaire */}
          <label htmlFor="time">Sélectionnez le créneau :</label>
          <select 
            id="time" 
            name="time" 
            value={time} 
            onChange={(e) => setTime(e.target.value)} 
            required
          >
            <option value="">--Choisir un créneau--</option>
            <option value="matin">Matin (8h00 - 12h00)</option>
            <option value="apres-midi">Après-midi (13h00 - 19h00)</option>
          </select>

          <button type="submit">Envoyer</button>
        </form>
      </div>
    </div>
  );
};

export default ReservationForm;

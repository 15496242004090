import React, { useState, useEffect } from "react";
import "./RGPDPopup.css"; // Import du fichier CSS

const RGPDPopup = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    // Vérifie si le consentement a déjà été donné
    const consentGiven = localStorage.getItem("rgpd-consent");
    if (!consentGiven) {
      setIsVisible(true);
    }
  }, []);

  const handleAccept = () => {
    // Sauvegarde le consentement
    localStorage.setItem("rgpd-consent", "true");
    setIsVisible(false);
  };

  const handleDecline = () => {
    // Redirige vers un lien spécifique
    window.location.href =
      "https://www.location-gardemeuble.fr/box-garde-meuble-angouleme-sos-box-572"; // Remplacez par votre lien
  };

  if (!isVisible) return null;

  return (
    <div className="rgpd-overlay">
      <div className="rgpd-popup">
        <h3>À propos des cookies sur ce site</h3>
        <p>
        Accepter les cookies de SOS BOX sur ce navigateur ? 

Nous utilisons les cookies pour comprendre, sécuriser, exploiter et fournir nos services. 
Pour en savoir plus sur les usages et les contrôles, consultez notre {" "}
          <a href="/politique-de-cookies">Politique de Cookies.</a>.
        </p>
        <div className="rgpd-buttons">
          <button className="rgpd-accept-button" onClick={handleAccept}>
            Accepter
          </button>
          <button className="rgpd-decline-button" onClick={handleDecline}>
            Refuser
          </button>
        </div>
      </div>
    </div>
  );
};

export default RGPDPopup;

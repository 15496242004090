import React from 'react';
import { Link } from 'react-router-dom'; // Importez Link pour une navigation interne
import './Hero.css';

const Hero = () => {
  return (
    <header className="hero">
      <div className="hero-content">
        <h1>SOSBOX - Votre solution de stockage à Angoulême</h1>
        <p>Des espaces sécurisés et flexibles pour tous vos besoins</p>
        <div className="btn-group">
          <Link to="/reserver-visite" className="btn">Réserver une visite</Link> {/* Utilisez Link au lieu de a */}
          <Link to="/demande-devis" className="btn">Demander un devis</Link>
        </div>
      </div>
    </header>
  );
};

export default Hero;

// ReservationSuccess.js
import React from 'react';
import { useNavigate } from 'react-router-dom';

const ReservationSuccess = () => {
  const navigate = useNavigate();

  const handleBackToHome = () => {
    navigate('/'); // Redirige vers la page d'accueil
  };

  return (
    <div className="reservation-success">
      <h2>Merci !</h2>
      <p>Votre réservation a été envoyée avec succès.</p>
      <button onClick={handleBackToHome}>Retourner au site originel</button>
    </div>
  );
};

export default ReservationSuccess;

// src/components/TermsOfUse.js
import React from 'react';
import './TermsOfUse.css'; 

const TermsOfUse = () => {
  return (
    <div className="terms-of-use">
      <h1>MENTIONS LEGALES - CONDITIONS GENERALES D’UTILISATION DU SITE  </h1>
      <p> Date de publication : 09 décembre 2024       </p>

      <h2>1. Objet</h2>
      <p>
      Les présentes Conditions Générales d’Utilisation (CGU) ont pour objet de définir les modalités d’accès et d’utilisation du site Internet édité par la société PINACLE SARL (ci-après « PINACLE SARL ») et accessible au lien suivant https://www.angouleme-sosbox.fr/  (ci-après dénommé "le Site"). L’utilisation du Site implique l’acceptation sans réserve des présentes CGU par tout utilisateur.       </p>

      <h2>2. Mentions légales </h2>
      <p>
      Éditeur du Site : 
La société PINACLE SARL  

Société à responsabilité limitée 
Siège social : 3 rue du Fouilloux 79340 Vasles 
RCS de Niort : 511 420 572  

 

Formulaire de contact : https://www.angouleme-sosbox.fr/contact   

Téléphone : 09 72 46 20 69 

 

 
Le Directeur de la publication est madame CHARGELEGUE Brigitte en sa qualité de Gérant de PINACLE SARL. 

 

Hébergeur du Site : 

OVH 

Société par actions simplifiée 

Siège social : 2 rue Kellermann 59100 Roubaix 

RCS de Lille Metropole : B 424 761 419       </p>

      <h2>3. Accès au Site</h2>
      <p>
      Le Site est accessible à tout utilisateur disposant d’un accès à Internet. PINACLE SARL se réserve le droit de suspendre, restreindre ou modifier l’accès au Site, notamment, pour des raisons de maintenance ou en cas de non-respect des présentes CGU.  </p>
      <h2>4. Services proposés</h2>
      <p> Le Site propose, en particulier : 

des informations sur les prestations de stockage de biens meubles proposées par PINACLE SARL ; 

une interface permettant aux utilisateurs de demander des devis et de réserver des visites des boxes de stockage ; 

des réponses aux questions fréquentes ; 

des services de contact et d’assistance client. 

 

Les services décrits sur le Site sont fournis sous réserve des termes et conditions spécifiques applicables aux prestations.      </p>

      <h2>5. Utilisation du Site </h2>
      <p> L’utilisateur s’engage à utiliser le Site conformément aux lois et règlements en vigueur, ainsi qu’aux présentes CGU.  

 

L’utilisateur s’engage notamment à ne pas : 

reproduire, copier ou exploiter un ou plusieurs contenu(s) du Site à des fins commerciales et sans autorisation écrite préalable  ; 

perturber le bon fonctionnement du Site ou tenter d’y accéder de manière non autorisée ; 

introduire des virus ou tout autre élément malveillant susceptible d’altérer le Site ou ses fonctionnalités, 

télécharger, poster, transmettre, distribuer ou publier de quelque façon que ce soit, par l'intermédiaire du Site, des contenus susceptibles : 

 d’être qualifié d’infractions pénales ; 

de porter atteinte i) à l’ordre public et aux bonnes mœurs (notamment l’apologie des crimes contre l’humanité, l’incitation à la haine raciale, la pornographie enfantine, etc.) ii) à l’image et/ou à la réputation de PINACLE SARL, iii) aux droits de tiers notamment par des actes d’usurpation d’identité, de contrefaçon ou toute autre atteinte aux droits de propriété intellectuelle ou de la personnalité ; 

et plus généralement, de nuire à PINACLE SARL (tels que, notamment, actes de concurrence déloyale ou parasitaire à l’égard de PINACLE SARL ou d’un tiers. 
       </p>

      <h2>6. Propriété intellectuelle</h2>
      <p> L'ensemble des éléments constituant le Site, incluant son architecture, son graphisme et ses contenus (tels que les textes, images, logos, graphismes, logiciels, photographies, dessins, modèles, marques, et bases de données), est protégé par les lois en vigueur relatives à la propriété intellectuelle.  

 

Toute utilisation non autorisée de tout ou partie de ces éléments, incluant, sans limitation, la reproduction, représentation, modification, distribution ou exploitation, par tout procédé et sur tout support, est strictement interdite sans l’autorisation écrite et préalable des ayants droit. De telles actions sont susceptibles de constituer une contrefaçon passible de sanctions civiles et pénales conformément à la législation en vigueur. 

 

Le Site et ses contenus sont réservés à une utilisation strictement personnelle et non commerciale. Toute violation de ces dispositions pourra donner lieu à des poursuites judiciaires.       </p>

      <h2>7. Responsabilité</h2>
      <p> PINACLE SARL s’efforce de garantir l’exactitude et la mise à jour des informations diffusées sur le Site, mais ne peut être tenue responsable en cas de : 

inexactitudes ou omissions dans les contenus disponibles sur le Site ; 

dysfonctionnements techniques empêchant l’accès total ou partiel au Site ; 

dommages directs, indirects ou immatériels résultant de l’utilisation du Site ou des services proposés. 

 

L’Utilisateur est seul responsable de l’utilisation qu’il fait des informations accessibles sur le Site. 

 

L’Utilisateur est également informé des aléas techniques inhérents à Internet, notamment les interruptions ou ralentissements pouvant affecter l’accès au Site.  

 

PINACLE SARL décline toute responsabilité en cas : 

d’indisponibilité totale ou partielle du Site ; 

de dysfonctionnements liés au réseau Internet ; 

de l’incompatibilité entre le Site et tout fichier, navigateur, programme ou outil utilisé pour y accéder. 

 

PINACLE SARL ne pourra être tenue responsable de tout dommage résultant : 

de l’utilisation ou de la navigation sur le Site ; 

de la diffusion par un tiers de programmes malveillants tels que des virus informatiques par l’intermédiaire du Site. 

 

En tout état de cause, PINACLE SARL s’engage à mettre en œuvre les mesures nécessaires pour limiter les risques et assurer un accès sécurisé au Site, dans la limite des contraintes techniques inhérentes à son fonctionnement.       </p>

      <h2>8. Liens hypertextes</h2>
      <p> Le Site peut contenir des liens vers des sites tiers. PINACLE SARL n’exerce aucun contrôle sur ces sites et décline toute responsabilité quant à leur contenu ou leur fonctionnement.      </p>
      <h2>9. Données personnelles </h2>
      <p> L’utilisation du Site peut impliquer la collecte et le traitement de Données personnelles. Ces opérations sont réalisées conformément à la Politique de Confidentialité disponible sur le Site. </p>

      <h2>10. Politique de cookies </h2>
      <p> Les dispositions relatives aux cookies figurent dans la Politique de Cookies.   </p>

      <h2>11. Modification des CGU </h2>
      <p> PINACLE SARL se réserve le droit de modifier les présentes CGU à tout moment. Les modifications entreront en vigueur dès leur publication sur le Site. L’utilisateur est invité à consulter régulièrement les CGU pour prendre connaissance des éventuelles mises à jour. </p>
      <h2>12. Droit applicable et attribution de competence </h2>
      <p> Les présentes CGU sont régies par le droit français. </p>
      <h2>12.1. Utilisateur ayant la qualité de consommateur </h2>
      <p> Conformément aux dispositions des articles L.611-1 et suivants et R.616-1 du Code de la consommation, en cas de litige relatif à l’interprétation ou l’exécution des présentes Conditions Générales d’Utilisation (CGU) ou à l’utilisation du Site, tout utilisateur ayant la qualité de consommateur peut recourir gratuitement à un médiateur de la consommation en vue de parvenir à une résolution amiable du différend. 
          En cas de litige, l’utilisateur peut nous contacter directement et nous vous communiquerons le contact d’un médiateur agréé (https://www.cnpm-mediation.org/liste-mediateur.php). En cas de litige non résolu par le biais de la médiation, l’utilisateur consommateur, au sens de l’article liminaire du Code de la consommation, peut saisir : 
          Soit le tribunal du lieu de son domicile ; 
          Soit le tribunal du lieu d'exécution de la prestation de service ; 
          Soit le tribunal du lieu du fait dommageable ou du lieu où le dommage a été subi, au choix de l’utilisateur.   </p>
      <h2>12.2. Utilisateur ayant la qualité de professionnel   </h2>
      <p>En cas de litige opposant PINACLE SARL et un utilisateur ayant la qualité de professionnel, LES TRIBUNAUX COMPETENTS SONT CEUX DU RESSORT DU SIEGE SOCIAL DE PINACLE SARL. </p>
      <h2>13. Contact</h2>
      <p> Pour toute question relative aux présentes CGU ou au fonctionnement du Site, veuillez nous contacter sur le formulaire de contact accessible au lien suivant : https://www.angouleme-sosbox.fr/contact. </p>
    
    </div>
  );
};

export default TermsOfUse;

import React from 'react';
import './Services.css'; // Importation du fichier CSS

function Services() {
    const services = [
        { title: 'Stockage sécurisé', description: 'Nos entrepôts sont équipés de systèmes de sécurité avancés pour protéger vos biens.' },
        { title: 'Accessibilité 24/7', description: 'La création d un code personnalisé avec un accès 24H/24 de jour comme de nuit.' },
        { title: 'Assistance client', description: 'Notre équipe est disponible pour répondre à toutes vos questions et vous aider.' },
        { title: 'Gestion de vos déchets', description: 'Vous souhaitez vider votre box ? pas de panique notre équipe s\'en occupe .' },
    ];

    return (
        <section className="services-section">
            <h2 className="services-title">Nos Services</h2>
            <div className="services-list">
                {services.map((service, index) => (
                    <div className="service-item" key={index}>
                        <h3>{service.title}</h3>
                        <p>{service.description}</p>
                    </div>
                ))}
            </div>
        </section>
    );
}

export default Services;

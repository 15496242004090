import React from 'react';
import './HelpSelection.css'; // Assurez-vous que le chemin d'accès est correct

const HelpSection = () => {
  return (
    <section className="need-help">
      <div className="container">
        <h2>Besoin d'aide ?</h2>
        <div className="help-card">
          <h3>Contactez-nous</h3>
          <p>Notre équipe est là pour répondre à toutes vos questions.</p>
          <a href="contact" className="btn">Nous contacter</a>
        </div>
      </div>
    </section>
  );
};

export default HelpSection;

import React, { useState } from 'react';
import './DevisForm.css'; // Assure-toi d'importer le CSS
import { useNavigate } from 'react-router-dom';

const DevisForm = () => {
  const navigate = useNavigate(); // Hook pour la navigation
  const [isSubmitted, setIsSubmitted] = useState(false); // État pour le message de succès

  const handleBack = () => {
    navigate(-1); // Retourne à la page précédente
  };

  if (isSubmitted) {
    return (
      <div className="devis-form-container">
        <div className="devis-form">
          <h2>Merci !</h2>
          <p>Merci pour votre réservation ! Nous vous recontacterons dans les plus brefs délais afin de répondre à votre demande. Nous vous remercions pour votre patience et votre confiance.
            N'hésitez pas à nous contacter si vous avez des questions supplémentaires. Nous restons à votre disposition pour toute assistance.</p>
          <button onClick={() => navigate('/')}>Retourner au site originel</button>
        </div>
      </div>
    );
  }

  return (
    <div className="devis-form-container">
      <div className="devis-form">
        {/* Croix en haut à droite */}
        <button className="close-button" onClick={handleBack}>✖</button>

        <h2>Demande de devis</h2>
        <form action="https://formspree.io/f/mpwzwarq" method="POST">
          <input type="text" name="name" placeholder="Votre nom" required />
          <input type="email" name="email" placeholder="Votre email" required />
          <input type="téléphone" name="numéro téléphone" placeholder="Votre numéro de téléphone" required />
          <textarea name="message" placeholder="Votre message" required></textarea>
          <button type="submit">Envoyer</button>
        </form>
      </div>
    </div>
  );
};

export default DevisForm;

// src/components/politique-de-cookies.js
import React from 'react';
import './TermsOfUse.css'; // Assure-toi que ce fichier CSS existe


const PolitiqueDeCookies = () => {
  return (
    <div className="terms-of-use">
      <h1>POLITIQUE DE COOKIES   </h1>
      <p> Date de publication : 09 décembre 2024       </p>
      <p> Notre site internet utilise des cookies pour améliorer votre expérience utilisateur et analyser le trafic. Cette politique de cookies explique ce que sont les cookies, comment nous les utilisons, et les choix dont vous disposez concernant leur utilisation. </p>

      <h3>Qu'est-ce qu'un cookie ?    </h3>
      <p> Un cookie est un petit fichier texte stocké sur votre appareil (ordinateur, tablette ou téléphone) lorsque vous visitez un site internet. Les cookies permettent de recueillir des informations sur vos habitudes de navigation afin d'améliorer votre expérience. 
          Base juridique pour les cookies 
          Certains cookies utilisés sur ce site sont strictement nécessaires à son bon fonctionnement et ne nécessitent pas votre consentement. Pour les cookies analytiques et tout autre cookie non essentiel, nous nous appuyons sur votre consentement explicite  </p>
      <h3>Les cookies que nous utilisons     </h3>
      <p> Nous utilisons les Cookies analytiques sur notre Site. 

 

Ces cookies sont utilisés pour collecter des informations sur la manière dont les visiteurs utilisent notre Site. Les données recueillies nous aident à comprendre les comportements des utilisateurs et à améliorer nos services. 

 

Nous utilisons les services tiers de Google Analytics pour collecter des informations anonymisées sur la manière dont vous utilisez notre site. Ces données peuvent inclure des informations sur votre appareil, votre localisation approximative et vos interactions sur le site. 

Fournisseur : Google LLC 

Transferts hors de l'UE : Les données collectées peuvent être transférées et traitées en dehors de l'Union Européenne, notamment aux États-Unis. Ces transferts sont encadrés par des garanties adéquates, telles que les clauses contractuelles types approuvées par la Commission Européenne. 

 

Les cookies utilisés dans ce cadre sont les suivants : 

Nom du cookie : _ga 

Domaine : .angouleme-sosbox.fr 

Finalité : Utilisé pour distinguer les utilisateurs (Google Analytics, suivi des visites). 

Durée de conservation : 2 ans 

Consentement requis : Oui 

 

Nom du cookie : _ga_46362BRCSB 

Domaine : .angouleme-sosbox.fr 

Finalité : Utilisé pour persister l'état de la session (Google Analytics). 

Durée de conservation : 2 ans 

Consentement requis : Oui 

Gestion des cookies 

Lors de votre première visite sur notre site, une bannière vous informe de l’utilisation des cookies et vous propose de donner ou de refuser votre consentement pour leur utilisation. 

Vous pouvez à tout moment modifier vos préférences concernant les cookies via : 

Les paramètres de votre navigateur ; 

Notre outil de gestion des cookies accessible via le lien "Gérer les cookies" situé en bas de chaque page du Site. 

Veuillez noter que le refus de certains cookies peut impacter la fonctionnalité de certaines parties du Site. </p>
<h3>Contact  </h3>
<p> Si vous avez des questions sur notre politique de cookies ou sur vos droits, vous pouvez nous contacter à l'adresse suivante : https://www.angouleme-sosbox.fr/contact. </p>

    </div>
  );
};

export default PolitiqueDeCookies;

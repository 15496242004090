// src/components/WhyChooseSOSBox.js
import React, { forwardRef } from 'react'; // Importer React et forwardRef
import './WhyChooseSOSBox.css'; // Assure-toi que ce fichier existe aussi

const WhyChooseSOSBox = forwardRef((props, ref) => {
  return (
    <div ref={ref} className="why-choose-sosbox">
      <div className="text-content">
        <h2>Pourquoi choisir un SOSBox ?</h2>
        <p>
          Nous offrons des espaces de stockage sécurisés et flexibles adaptés à tous vos besoins. Que vous ayez besoin d'un petit espace pour quelques objets ou d'un grand entrepôt pour vos affaires, SOSBox est là pour vous.
        </p>
        <p>
          Avec un accès 24/7, des installations modernes et une sécurité renforcée, vous pouvez être sûr que vos biens sont entre de bonnes mains.
        </p>
      </div>
      <div className="image-content">
        <img src="https://i.postimg.cc/TwFWXggq/sos-box-angouleme-1337266732.jpg" alt="SOSBox" />
      </div>
    </div>
  );
});

export default WhyChooseSOSBox;

// src/components/ContactForm.js
import React, { useState } from 'react';
import './ContactForm.css'; // Assurez-vous d'importer le CSS

const ContactForm = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const response = await fetch('https://formspree.io/f/xjkvkbee', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ name, email, message }),
    });

    if (response.ok) {
      setSuccess(true);
      setName('');
      setEmail('');
      setMessage('');
    } else {
      setError(true);
    }
  };

  return (
    <div className="contact-form-container">
      <h2>Contactez-nous</h2>
      {success && <p>Votre message a été envoyé avec succès !</p>}
      {error && <p>Une erreur s'est produite. Veuillez réessayer.</p>}
      <form onSubmit={handleSubmit}>
        <input
          type="text"
          name="name"
          placeholder="Votre nom"
          value={name}
          onChange={(e) => setName(e.target.value)}
          required
        />
        <input
          type="email"
          name="email"
          placeholder="Votre email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        <textarea
          name="message"
          placeholder="Votre message"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          required
        ></textarea>
        <button type="submit">Envoyer</button>
      </form>
    </div>
  );
};

export default ContactForm;
